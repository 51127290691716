import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentUserState } from '../../../core/store/current-user/current-user.state';
import { PortalApiRepository } from '../../../core/repositories/portal-api-repository';
import {
  DataProcessingAgreementRequestDTO,
  DataProcessingAgreementResponseDTO,
} from '../models/DataProcessingAgreementDTO';
import { toDataProcessingAgreementRequestDTO } from '../mappers/dto-mappers';
import { CheckoutSessionResponseDTO } from '../models/CheckoutSessionDTO';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends PortalApiRepository {
  constructor() {
    super();
  }

  downloadFile(fileName: string): Observable<Blob> {
    const path = `/Documents/${fileName}`;
    return this.download(path);
  }

  getDataProcessingAgreement(): Observable<DataProcessingAgreementResponseDTO> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);

    const path = `/organizations/${orgId}/DataProcessingAgreement`;
    return this.get<DataProcessingAgreementResponseDTO>(path);
  }

  saveDataProcessingAgreement(
    contractformData: Partial<{
      fullName: string;
      responsibleBody?: string;
    }>,
  ): Observable<void> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);
    const path = `/organizations/${orgId}/DataProcessingAgreement`;
    const payload = toDataProcessingAgreementRequestDTO(contractformData);

    return this.post<DataProcessingAgreementRequestDTO, void>(path, payload);
  }

  createCheckoutSession(): Observable<CheckoutSessionResponseDTO> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);

    const path = `/organizations/${orgId}/CheckoutSession`;
    return this.post<void, CheckoutSessionResponseDTO>(path);
  }
}
